label {
    display: block;
}

input[type="submit"],
input[type="time"] {
    appearance: none;
}

input[type="date"],
input[type="email"],
input[type="number"],
input[type="text"],
input[type="time"],
textarea {
    padding: 0.5em;
    line-height: 1em;
    height: 2.5em;
    width: 100%;
    display: block;
    border: 1px solid $purplettt;
    border-radius: 0;
    color: $black;
}

textarea {
    height: auto;
}

form.search-bar {
    ///////////////////////////////////////////////////////////////////////////////////
    $base-spacing: 1.5em !default;
    $base-border-color: gainsboro !default;
    $base-line-height: 1.5em !default;
    //////////////////////////////////////////////////////////////////////////////////
    $search-bar-border-color: $base-border-color;
    $search-bar-border: 1px solid $search-bar-border-color;
    $search-bar-background: lighten($search-bar-border-color, 10%);
    position: relative;
    input[type=search] {
        @include appearance(none);
        @include transition(border-color);
        background-color: white;
        border: 1px solid $base-border-color;
        box-sizing: border-box;
        display: block;
        font-size: 1em;
        font-style: italic;
        margin: 0;
        padding: ($base-spacing / 3) ($base-spacing / 3);
        position: relative;
        width: 100%;
    }
    input[type=submit] {
        appearance: none;
        border: 0;
        margin: 0;
        border-radius: 0;
        box-shadow: none;
        @include position(absolute, 0em 0em 0em null);
        outline: none;
        padding: 5px 10px;
        &:hover {
            margin: 0;
            transform: translateY(0);
            border: 0;
        }
        img {
            height: 12px;
            opacity: 0.7;
        }
    }
}

.Mailchimp__signup {
    padding-top: 4em;
    padding-bottom: 4em;
}

.mc-field-group {
    margin-bottom: 0.5em;
    input,
    label {
        display: block;
        width: 100%;
    }
    input {
        background: $white;
    }
    @include media ($phablet-up) {
        label {
            @include span-columns(4);
            text-align: right;
        }
        input {
            @include span-columns(8);
            @include omega;
        }
    }
}

.mc-submit input[type="submit"] {
    float: right;
    margin-right: 0;
}

.close {
    cursor: pointer;
    position: absolute;
    font-size: 2em;
    top: .4em;
    right: .25em;
    line-height: 0;
    i {
        color: $white;
    }
    &:hover i {
        color: $black;
    }
}

.flash-message {
    @include clearfix;
    text-align: left;
    background: $blue;
    padding: 1em;
    width: 30em;
    max-width: 90%;
    position: relative;
    margin: 0 auto;
    @include media ($phablet-down) {
        // margin-right: 1em;
        // margin-left: 1em;
        max-width: 100%;
    }
    h2,
    h3,
    h4,
    p {
        color: $white;
        margin: 0;
    }
}
