.Image {
    img {
        width: 100%;
        height: auto;
        display: block;
    }
}

img.round {
    border-radius: 50%;
}

.image {
    position: relative;
    img {
        width: 100%;
        height: auto;
        display: block;
    }
    .image-desc {
        position: absolute;
        bottom: 0;
        padding: 0.5em;
        background: $blacktt;
        p {
            font-size: 0.75em;
            margin: 0;
            color: $whitettt;
            &:hover {
                color: $whitet;
            }
        }
    }
}

.circular-image {
    .Case-Studies & {
        margin-top: 2em;
    }
    .Testimonials & {}
    .case-study &,
    .Testimonials & {
        margin: 0 auto 1em;
        max-width: 12em;
    }
    img {
        width: 100%;
        display: block;
    }
    border-radius: 50%;
    overflow: hidden;
}

.logo {
    svg {
        $logowidth: 172.562px;
        $logoheight: 97.916px;
        width: $logowidth;
        height: $logoheight;
        .site-header & {
            @include media ($large-screen-down) {
                width: ($logowidth * .75);
                height: ($logoheight * .75);
            }
            @include media ($phablet-down) {
                width: ($logowidth * .6);
                height: ($logoheight * .6);
            }
        }
    }
    svg {
        g.text {
            fill: $black;
        }
        g.jlm {
            stroke: $darkblack;
        }
    }
    a:hover {
        svg {
            g.text {
                fill: $darkpurple;
            }
            g.jlm {
                stroke: $darkpurple;
                fill: $purplettt;
            }
        }
    }
}

.accreditations {
    text-align: center;
    .accreditation {
        display: inline-block;
        height: 60px;
        width: auto;
        margin: 1em;
        opacity: .3;
        transition: opacity .3s;
        &:hover {
            opacity: 1;
        }
        @include media ($phablet-down) {
            display: block;
            margin: .5em auto;
        }
    }
}
