.footer-nav,
.main-nav {
    ul {
        @include list-reset;
        margin-bottom: 0.5em;
        li {
            margin-top: 0.5em;
            font-size: 1.1em;
            a {
                font-weight: 300;
                transition: all 0s;
                border: 1px dotted transparent;
                display: block;
                transition: color 100ms;
                transition: border 100ms;
                padding-bottom: 0.25em;
                &:hover {
                    border-bottom: 1px dotted $white;
                }
            }
            > ul {
                margin-top: 1em;
                margin-bottom: 1.5em;
                li {
                    margin-top: 0;
                }
            }
        }
    }
}
.main-nav {
    .row {}
    .nav-wrapper {
        max-height: 100%;
        overflow-y: scroll;
       
    }
    display: none;
    position: fixed;
    top: 4em;
    top: 0;
    padding: 4em 1em 0;
    padding-top: 2em;
    @include media ($phablet-up) {
        padding: 5em 1.6em 3em;
        top: 2.1em;
        top: 0;
        // padding-top: 7.1em;
    }
    right: 0;
    bottom: 0;
    left: 0;
    background: $black;
    background: linear-gradient($ddarkblack, $black);
    // z-index: 50;
    div {}
    svg {
        margin-bottom: 2em;
        width: 10em;
        height: auto;
         g.text {
                fill: $white;
            }
            g.jlm {
                stroke: $white;
            }
    }
    ul {
        li {
            text-align: left;
            a {
                color: $white;
                display: block;
                border: 1px dotted transparent;
                &:active,
                &:hover {
                    color: $lightpurple;
                }
            }
            ul li a {
                color: $lightpurple;
            }
        }
    }
}
.main-nav.open {
    display: block;
}

.b-mobile {
    @include media ($phablet-up){
        display: none;
    }
}


.sub-nav {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        @include clearfix;
        margin-top: 0.5em;
        li {
            display: block;
            box-shadow: $boxsh;
            @include media ($phablet-down) {
                 @include span-columns(4 of 8);
                @include omega(2n);
                margin-bottom: 3.58%;
            }
            @include media ($phablet-up) {
                 @include span-columns(8 of 24);
                @include omega(3n);
                margin-bottom: 1.166%;
            }
            a {
                background: $purple;
                color: $white;
                padding: 1em;
                display: block;
                font-family: $title;
                font-size: 1em;
                position: relative;                
                @include media ($phablet-down){
                    font-family: $body;
                    font-size: .75em;
                }
                @include media ($phablet-up) {
                    padding-right: 2em;
                }
                &:hover {
                    background: $darkpurple;
                }
                &:after {
                    position: absolute;
                    right: 1em;
                    top: 1em;
                    padding: 0 0 0 0.5em;
                    content: '';
                    color: $darkpurple;
                    float: right;
                    font-family: fontawesome;
                }
                &:hover:after {
                    color: $purple;
                }
            }
        }
    }
}
