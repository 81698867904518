.header--fixed {
    position: fixed;
    // z-index: 10;
    right: 0;
    left: 0;
    top: 0;
}

.site-header {
    background: $white;
    background: $whitet;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    // margin-bottom: 2em;
    padding-bottom: 1em;
    @include media ($phablet-down) {
        justify-content: flex-start;
        padding-top: 1em;
    }
    @include media ($phablet-up) {
        padding-top: 2em;
    }
    .logo,
    [class*=nav-] {
        // border: 1px solid $grey3;
    }
    [class*=nav-] {
        display: flex;
        align-items: center;
        @include media ($phablet-down) {
            display: none;
        }
        ul {
            list-style: none;
            padding: 0;
            li {
                display: inline-block;
                @include media($phablet-desktop) {
                    display: block;
                    padding: 0 1em;
                }
                a {
                    padding: 1em 1.5em;
                    font-family: $title;
                    color: $grey3;
                    font-weight: normal;
                    font-size: 1.1em;
                    @include media ($phablet-llarge-screen) {
                        padding: 1em 0.85em;
                        font-size: 0.95em;
                    }
                    @include media ($desktop-down) {
                        font-size: 0.9em;
                    }
                    &:hover {
                        color: $ddarkpurple;
                    }
                }
            }
        }
    }
    .nav-left {
        padding-right: 1em;
        @include media($phablet-desktop) {
            text-align: right;
        }
    }
    .nav-right {
        padding-left: 1em;
        @include media($phablet-desktop) {
            text-align: left;
        }
    }
}

.headroom {
    transition: transform 200ms linear;
}

.headroom--pinned {
    transform: translateY(0%);
}

.headroom--not-top {
    box-shadow: $boxsh;
}

.headroom--unpinned {
    transform: translateY(-100%);
}
