.top-bar {
    background: $darkpurple;
    padding: 0.5em 0;
    position: relative;
    // z-index: 60;
    &,
    a {
        color: $white;
        font-weight: normal;
        transition: all 0s;
        i {
            color: $black;
        }
    }
    a:hover {
        color: $white;
        i {
            color: $grey;
        }
    }
    p {
        margin: 0;
    }
    a:last-child {
        margin-left: 2em;
    }
    @include media ($phablet-down) {
        .top-bar-tagline {
            margin-bottom: 0;
        }
        [class*=col-] {
            display: block;
            padding: 0.25em 0;
            p.small,
            p.small a {
                font-size: 12px;
            }
        }
    }
    @include media ($phablet-up) {
        .top-bar-contact {
            text-align: right;
            a,
            span {
                margin-left: 1em;
            }
        }
    }
}

.menu {
    color: $white;
    display: inline-block;
    // text-shadow: 1px 1px 0 $blacktt;
    animation: btnWiggle 5s infinite;
    @include media ($phablet-down) {
        display: none;
    }
    &:hover {
        cursor: pointer;
        color: $white;
        i {
            color: $grey;
        }
    }
}

@keyframes btnWiggle {
    0% {
        transform: rotate(0deg);
    }
    2% {
        transform: rotate(-4deg);
        transform: scale3d(1.1, 1.1, 1);
    }
    3.5% {
        transform: rotate(4deg);
    }
    5% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
