$lightblue: #a3c7db;
$llightblue: lighten($lightblue, 10);
$lllightblue: lighten($lightblue, 17);
$blue : rgb(51,159,205);
// $darkblue : #569ac0;
$darkblue: darken($blue, 10);
$ddarkblue : #377ea4;
$bluet: transparentize($blue, .15);
$bluett: transparentize($blue, .5);
$bluettt: transparentize($blue, .75);


$purple: #8585a0;
$lightpurple: lighten($purple, 10);
$llightpurple: lighten($purple, 15);
$lllightpurple: lighten($purple, 2);
$darkpurple: darken($purple, 15);
$ddarkpurple: darken($purple, 25);
$dddarkpurple: darken($purple, 35);
$purplet: transparentize($purple, .2);
$purplett: transparentize($purple, .5);
$purplettt: transparentize($purple, .75);


$black: #333333;
$darkblack: darken($black, 10);
$ddarkblack: darken($black, 15);
$blackt: transparentize($black, .2);
$blacktt: transparentize($black, .5);
$blackttt: transparentize($black, .75);
$blacktttt: transparentize($black, .9);

$white: #ffffff;
$whitet: transparentize($white, .05);
$whitett: transparentize($white, .4);
$whitettt: transparentize($white, .6);

$grey:			#535965;
$darkgrey: darken($grey, 10);
$ddarkgrey: darken($grey, 20);
$grey0: lighten($grey, 3);
$grey1: lighten($grey, 6);
$grey2: lighten($grey, 9);
$grey3: lighten($grey, 15);
$grey4: lighten($grey, 20);
$grey5: lighten($grey, 30);
$grey6: lighten($grey, 40);
$grey7: lighten($grey, 50);









$coral: #f69d8f;
$lightcoral: lighten($coral, 10);
$darkcoral: darken($coral, 10);
$ddarkcoral: darken($coral, 15);
$coralt: transparentize($coral, .2);
$coraltt: transparentize($coral, .5);
$coralttt: transparentize($coral, .75);


$green: #8AC80B;
$lightgreen: lighten($green, 10);
$llightgreen: lighten($green, 15);
$lllightgreen: lighten($green, 2);
$darkgreen: darken($green, 10);
$ddarkgreen: darken($green, 15);
$dddarkgreen: darken($green, 20);
$greent: transparentize($green, .2);
$greentt: transparentize($green, .5);
$greenttt: transparentize($green, .75);

$aqua: #66CCB4;
$lightaqua: lighten($aqua, 10);
$llightaqua: lighten($aqua, 15);
$lllightaqua: lighten($aqua, 2);
$darkaqua: darken($aqua, 10);
$ddarkaqua: darken($aqua, 15);
$dddarkaqua: darken($aqua, 25);
$aquat: transparentize($aqua, .2);
$aquatt: transparentize($aqua, .5);
$aquattt: transparentize($aqua, .9);

$brown: #805c3a;
$lightorange: #fcba6a;
$orange: #faab4e;
$darkorange: #e88e23;
// $lightyellow:#f3e176;
$yellow: #fdec5f;
$lightyellow: lighten($yellow, 10);
$darkyellow: #cbcb30;